import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import Script from "next/script";
import "bootstrap/scss/bootstrap.scss";
import "bootstrap/dist/css/bootstrap.min.css";

// ========= Plugins CSS START =========
import "../public/css/plugins/feature.css";
import "../public/css/plugins/animation.css";
import "../node_modules/sal.js/dist/sal.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-tooltip/dist/react-tooltip.css";
// ========= Plugins CSS END =========

import "../public/scss/style.scss";
import Loading from "@/components/Loading/Loading";

// GTM için custom hook
function useGTM() {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'GTM-5DNF92ZC');
  }, []);
}

export default function App({ Component, pageProps }) {
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  useGTM(); // GTM'i başlat

  useEffect(() => {
    if (typeof window !== "undefined") {
      require("bootstrap/dist/js/bootstrap.bundle.min.js");

      const handleStart = (url) => url !== router.asPath && setLoading(true);
      const handleComplete = () => setLoading(false);

      router.events.on("routeChangeStart", handleStart);
      router.events.on("routeChangeComplete", handleComplete);
      router.events.on("routeChangeError", handleComplete);

      // Çerez onayını başlat
      initCookieConsent();

      return () => {
        router.events.off("routeChangeStart", handleStart);
        router.events.off("routeChangeComplete", handleComplete);
        router.events.off("routeChangeError", handleComplete);
      };
    }
  }, []);

  const initCookieConsent = () => {
    import("cookieconsent")
      .then((cookieconsent) => {
        import("cookieconsent/build/cookieconsent.min.css");

        window.cookieconsent.initialise({
          palette: {
            popup: { background: "#0072ff" },
            button: {
              background: "#ffffff",
              color: "#0072ff",
              border: "1px solid #0072ff",
            },
          },
          theme: "classic",
          content: {
            message: "This site uses cookies to give you the best experience.",
            dismiss: "Accept",
            link: "More information",
            href: "/privacy-policy",
          },
          onInitialise: function (status) {
            // GTM artık burada yüklenmeyecek
          },
          onStatusChange: function (status) {
            // GTM artık burada yüklenmeyecek
          },
        });
      })
      .catch((error) => {
        console.error("Failed to load cookieconsent:", error);
      });
  };

  return (
    <>
      <Head>
        <link
          rel="canonical"
          href={`https://www.nisus-ai.com${router.asPath}`}
        />
      </Head>
      {/* GTM script */}
      <Script
        id="gtm-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-5DNF92ZC');
          `,
        }}
      />
      {loading ? <Loading /> : <Component {...pageProps} />}
    </>
  );
}